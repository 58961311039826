import * as React from 'react';
import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import { Highlight } from '../components/ui/highlight/highlight';
import { Card, CardType } from '../components/ui/card/card';
import { InfoList } from '../components/ui/info-list/info-list';

const UkraineFoundations: React.FC = () => {
  const { t } = useTranslation();

  const cards: CardType[] = t('pages.foundations.cards', {
    returnObjects: true,
  });
  const list: Record<'content', string>[] = t(
    'pages.foundations.list.listItems',
    { returnObjects: true },
  );
  const translationsHomeInfoList: string[] = t('pages.home.info.lists', {
    returnObjects: true,
  });

  return (
    <Layout className="main__foundations">
      <Helmet>
        <meta name="keywords" content={t('pages.foundations.metaKeywords')} />
        <meta
          name="description"
          content={t('pages.foundations.metaDescription')}
        />
        <title>{t('pages.foundations.documentTitle')}</title>
      </Helmet>
      <h1>{t('pages.foundations.title')}</h1>
      <div className="home__info">
        <div className="container--fluid">
          <p className="home__info-title">
            <Trans
              i18nKey="pages.home.info.title"
              components={{ highlight: <Highlight /> }}
            />
          </p>
          <InfoList lists={translationsHomeInfoList} />
        </div>
      </div>
      <div className="container--fluid">
        {cards.map(({
          title, text, image, imageAlt, 
        }, index) => (
          <Card
            key={text}
            text={text}
            title={title}
            isReversed={Boolean(index % 2)}
            image={`../../images/${image}`}
            imageAlt={imageAlt}
          />
        ))}

        <h2>
          <Trans
            i18nKey="pages.foundations.list.listHeading"
            components={{
              highlight: <Highlight />,
            }}
          />
        </h2>
        <ul className="list">
          {list.map(({ content }) => (
            <li key={content} className="list__item">
              <Trans
                i18nKey={content}
                components={{
                  a: <a target="_blank" />,
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default UkraineFoundations;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
