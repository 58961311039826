import React from 'react';
import './info-list.scss';

type InfoListType = {
  lists: string[];
};

export const InfoList: React.FC<InfoListType> = ({ lists }) => (
  <ol className="info-list">
    {lists.map((list) => <li key={list} className="info-list__item">{list}</li>)}
  </ol>
);
